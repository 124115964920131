import * as React from "react";

const SvgVectorBrown = (props) => (
	<svg width={417} height={449} viewBox="0 0 417 449" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M339.986 76.64c33.367 30.358 63.725 57.161 73.024 89.981 9.573 32.547-1.641 71.384-6.564 111.588-4.649 39.931-3.282 81.503-21.059 113.229-17.504 31.453-53.88 53.059-90.802 56.341-36.923 3.556-74.392-10.94-116.511-19.145s-88.614-10.12-122.255-32.273C22.18 373.934 1.393 331.268.572 289.149c-1.094-42.119 18.051-83.964 35.555-127.998 17.504-44.033 33.64-90.529 66.187-121.16 32.547-30.632 81.23-45.948 124.169-36.65 42.94 9.3 79.862 43.213 113.503 73.299Z"
			fill="#4F091D"
			fillOpacity={0.7}
		/>
	</svg>
);

export default SvgVectorBrown;
