import * as React from "react";

const SvgVectorWhite = (props) => (
	<svg width={416} height={392} viewBox="0 0 416 392" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M294.287 73.632c27.417 28.405 55.822 43.966 81.016 69.901 25.194 25.688 47.177 61.503 38.285 89.908-8.892 28.405-48.659 49.153-76.57 79.04-27.911 29.887-43.719 68.913-69.16 76.817-25.441 8.151-60.268-14.82-100.776-23.959-40.508-9.386-86.697-4.94-118.807-23.465-31.863-18.278-49.894-59.28-47.671-98.059 2.223-38.779 24.947-75.335 48.659-104.975s48.412-52.364 76.323-80.275C153.25 30.407 184.125-2.938 212.53.767c28.652 3.705 54.587 44.707 81.757 72.865Z"
			fill="#F5EEDC"
			fillOpacity={1.0}
		/>
	</svg>
);

export default SvgVectorWhite;
