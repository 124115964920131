import * as React from "react";

const SvgVectorYellow = (props) => (
	<svg width={457} height={352} viewBox="0 0 457 352" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M422.538 69.715c52.173 40.579 110.484 85.932 111.507 132.308.682 46.035-55.924 93.093-107.756 115.258-52.173 21.824-99.231 18.414-159.929 32.395-61.039 13.64-135.718 44.33-190.96 22.506-55.242-21.824-91.047-96.503-67.518-147.653 23.188-51.15 106.051-79.112 161.293-119.691C224.417 64.6 252.379 11.063 288.866 2.197c36.487-8.525 81.84 26.939 133.672 67.518Z"
			fill="#EED75D"
			fillOpacity={1.0}
		/>
	</svg>
);

export default SvgVectorYellow;
