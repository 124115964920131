import { SiGreensock, SiJavascript, SiSass, SiReact, SiTailwindcss, SiHtml5 } from "react-icons/si";

export const Skill = [
	{
		id: 1,
		icon: <SiHtml5 />,
		color: "#e34c26",
		name: "HTML/CSS",
		desc: "現在の業務におきましては主にHTMLとCSS（SCSS）を用いてサイトを制作しております。レスポンシブ対応はもちろん、更新を意識した簡潔なコードを心がけています。",
	},
	{
		id: 2,
		icon: <SiSass />,
		color: "#CE679A",
		backgroundColor: "#fff",
		name: "SCSS",
		borderRadius: "50%",
		desc: "業務におきましてもSCSSを使用しております。BEMでの命名と組み合わせて、より効率的なCSS設計を意識してコーディングしています。",
	},
	{
		id: 3,
		icon: <SiGreensock />,
		color: "#fff",
		backgroundColor: "#81C402",
		borderRadius: "15%",
		name: "GSAP",
		desc: "本ポートフォリオのトップページのテキストアニメーションにはGSAP使用しています。スクロールアニメーションやタイムラインを用いたKVなどに使用します。",
	},
	{
		id: 4,
		icon: <SiJavascript />,
		color: "#f0db4f",
		backgroundColor: "#fff",
		name: "JavaScript/jQuery",
		desc: "タブ、アコーディオン、ハンバーガーメニューなどの実装にはjQueryを使用することが多いですが、個人での制作ではJavaScriptを用いてTodoリストの作成なども行なっています。",
	},
	{
		id: 5,
		icon: <SiReact />,
		color: "#61dbfb",
		backgroundColor: "#292929",
		borderRadius: "10%",
		name: "React",
		desc: "本ポートフォリオはReactを用いて作成しました。styled-components・material-uiやtailwind CSSを用いたスタイリング、framer-motionでのアニメーション設定などが可能です。",
	},
	{
		id: 6,
		icon: <SiTailwindcss />,
		color: "#35B3EB",
		backgroundColor: "#fff",
		borderRadius: "50%",
		name: "tailwind CSS",
		desc: "基本的なクラスでのCSS適用やカスタマイズを行い、より素早いスタイリングを行うことが可能です。",
	},
];
