import * as React from "react";

const SvgVectorDarkYellow = (props) => (
	<svg width={254} height={308} viewBox="0 0 254 308" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M147.718 31.64c22.866 17.098 43.878 34.814 65.096 58.916 21.424 24.308 43.054 55.002 40.376 82.812-2.678 28.016-29.87 53.354-55.826 74.984-25.956 21.63-50.882 39.758-79.31 50.264-28.222 10.3-60.358 13.184-83.842.206-23.278-12.772-38.316-41.612-57.474-65.714-19.158-23.896-42.642-43.26-57.68-70.864-15.038-27.398-21.424-63.448-4.738-84.048S-29.236 52.24 1.458 38.644C32.152 25.048 53.576 3.212 76.648.534c23.278-2.678 47.998 14.008 71.07 31.106Z"
			fill="#E7E281"
		/>
	</svg>
);

export default SvgVectorDarkYellow;
