import * as React from "react";

const SvgVectorGreen = (props) => (
  <svg
    width={403}
    height={444}
    viewBox="0 0 403 444"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M266.04 109.715c38.64 45.885 95.22 66.585 121.095 113.16 25.53 46.23 20.355 118.335-18.285 165.255-38.64 47.265-110.745 69-160.425 46.575-49.68-22.425-76.935-89.355-106.26-136.275-29.67-46.92-61.755-74.175-82.455-122.13-20.7-47.955-30.015-116.265-.345-162.15 29.67-46.23 97.98-69.69 142.83-45.54C206.7-7.585 227.4 63.83 266.04 109.715Z"
      fill="#78AFA0"
    />
  </svg>
);

export default SvgVectorGreen;
